<template>

  <user-form
    v-if="userFormToUpdate"
    :title="title"
    :submit-button-text="submitButtonText"
    :user-form-to-update="userFormToUpdate"
    @submitUserForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserForm from './UserForm.vue'

export default {
  components: {
    UserForm,
  },
  data() {
    return {
      title: 'Mise à jour d\'un utilisateur',
      submitButtonText: 'Modifier',
      userFormToUpdate: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      const userId = router.currentRoute.params.id
      store.dispatch('users/fetchUserById', userId)
        .then(response => {
          this.userFormToUpdate = response.data
        })
    })
  },
  setup() {
    const toast = useToast()

    const onSubmit = formUserValue => {
      store.dispatch('users/updateUser', { userId: router.currentRoute.params.id, formUserValue })
        .then(() => {
          router.push({ name: 'users-list' })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erreur lors de la mise à jour de l\'utilisateur',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      onSubmit,
    }
  },
}
</script>
